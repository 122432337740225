<template>
    <span :class="clazz">{{ text }}</span>
</template>

<script>
export default {
  props: {
    value: Object,
    sign: Boolean,
    color: Boolean,
  },
  computed: {
    clazz() {
      if (this.color && this.value.amount > 0) {
        return 'green--text';
      }
      if (this.value.amount < 0) {
        return 'red--text';
      }
      return '';
    },
    text() {
      let c = null;
      if (this.$global.env && this.$global.env.currenciesByID[this.value.currency]) {
        c = this.$global.env.currenciesByID[this.value.currency];
      } else if (this.$global && this.$global.projectVirtualCurrenciesByCode[this.value.currency]) {
        c = this.$global.projectVirtualCurrenciesByCode[this.value.currency];
      } else {
        return '';
      }

      let exponent = 1;
      for (let i = 0; i < c.decimals; i += 1) {
        exponent *= 10;
      }
      const num = (this.value.amount / exponent).toFixed(c.decimals);
      let sign = '';
      if (this.sign && this.value.amount > 0) {
        sign = '+';
      }

      return `${sign}${num} ${c.symbol ? c.symbol : ''}`;
    },
  },
};
</script>
